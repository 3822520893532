import React from "react"
import { Box } from "rebass"
import { InternalLink } from "@fragments"
const examples = require("@constants/codeExamples");

const OtherExamples = ({exampleToSkip}) => {
  //listing all remaining faqs below is leading to indexing problems so making it 15 items instead
  const currentExampleLanguage = examples.find(e => e.query == exampleToSkip)
  const sameLangExamples = examples.filter(e => e.language == currentExampleLanguage.language)
  const examplesMinusCurrent = sameLangExamples.filter(e => e.query !== exampleToSkip);
  const examplesShuffled = examplesMinusCurrent.sort(() => 0.5 - Math.random());
  const random15Examples = examplesShuffled.slice(0, 15);
  return (
    <Box mt={[32, 62]}>
      <h2>Similar Code Examples</h2>
      <ul>
        {random15Examples
          .map(example => (
            <li style={{ marginBottom: 0 }} key={example.slug} className="list-item-with-spacing">
              <InternalLink to={`/code-examples/${example.slug}/`}>
                {example.query}
              </InternalLink>
            </li>
          ))}
      </ul>
    </Box>
  )
}

export default OtherExamples;
