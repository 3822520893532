import React from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import Answer from "@components/pages/code-examples/SinglePage/Answer.js"
import SideCTA from "@components/SideCTA"

const CodeExamplePage = ({ pageContext: { query, slug, answer } }) => {
  return (
    <>
      <Layout>
        <SEO
          title={`[Code Examples] ${query}`}
          description={`Detailed guide and code examples for \`${query}\`. Fast-track your DynamoDB skills.`}
          canonical={`https://dynobase.dev/code-examples/${slug}/`}
        />
        <Hero title={`${query} (Guide w/ Code Examples)`} includeAuthorCustom customBylineTerm="Provided" />
        <Answer answer={answer} questionToSkip={query}/>
        <FooterWithCTA />
      </Layout>
      <SideCTA />
    </>
  )
}

export default CodeExamplePage;